import {createSlice} from "@reduxjs/toolkit";

const initialTaxesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  taxForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const taxesSlice = createSlice({
  name: "taxes",
  initialState: initialTaxesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    taxFetched: (state, action) => {
      state.actionsLoading = false;
      state.taxForEdit = action.payload.taxForEdit;
      state.error = null;
    },
    taxesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
   
    // createuser
    taxCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // updatetax
    taxUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.rol.id) {
          return action.payload.rol;
        }
        return entity;
      });
    },
    // deleteuser
    taxDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteusers
    taxesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // taxesUpdateState
    taxesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
