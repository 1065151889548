import axios from "axios";
import { create } from 'apisauce';
import { getServer } from '../../../../utils/url';

export const LOGIN_URL = `${getServer()}/auth`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

const API = create({
  baseURL: LOGIN_URL,
});

export const ME_URL = "api/me";

export function login(email, password) {
  return API.post(LOGIN_URL, { email, password, strategy: 'local' });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}
