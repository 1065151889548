/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Catálogos</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/e-commerce"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account-states/customers">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Clientes</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/account-states/stations"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account-states/stations">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Gasolineras</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}
        <li
        className={`menu-item ${getMenuItemActive("/admin/prices", false)}`}
        aria-haspopup="true"
        >
        <NavLink className="menu-link" to="/admin/prices">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Dollar.svg"
                )}
              />
            </span>
            <span className="menu-text">Precios</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/admin/taxes", false)}`}
          aria-haspopup="true"
        >
        <NavLink className="menu-link" to="/admin/taxes">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Shopping/Dollar.svg"
              )}
            />
          </span>
          <span className="menu-text">Impuestos</span>
        </NavLink>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Administración</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/account-states",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/account-states">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Estados de Cuenta</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Estados de Cuenta</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {/*begin::3 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/account-states/companies"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/account-states/companies">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Empresas</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/account-states/companies"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/account-states/cfdi">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">CFDI's</span>
                </NavLink>
              </li>
              {/*end::3 Level*/}
            </ul>
          </div>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/admin/roles", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/roles">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Shield-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Roles</span>
          </NavLink>

        
        </li>

        <li
        className={`menu-item ${getMenuItemActive("/admin/users", false)}`}
        aria-haspopup="true"
        >
        <NavLink className="menu-link" to="/admin/users">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Shield-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Usuarios</span>
          </NavLink>
        </li>


        <li
        className={`menu-item ${getMenuItemActive("/admin/periods", false)}`}
        aria-haspopup="true"
        >
        <NavLink className="menu-link" to="/admin/periods">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Shield-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Periodos</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
