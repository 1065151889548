import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import {customersSlice} from '../app/modules/AccountStates/_redux/customers/customersSlice';
import {rolesSlice} from '../app/modules/Admin/_redux/roles/rolesSlice';
import { usersSlice } from '../app/modules/Admin/_redux/users/usersSlice';
import {productsSlice} from '../app/modules/ECommerce/_redux/products/productsSlice';
import {remarksSlice} from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import {specificationsSlice} from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';
import { pricesSlice } from '../app/modules/Admin/_redux/prices/pricesSlice';
import { taxesSlice } from '../app/modules/Admin/_redux/taxes/taxesSlice';
import { periodsSlice } from '../app/modules/Admin/_redux/periods/periodsSlice';
import { cfdisSlice } from '../app/modules/AccountStates/_redux/cfdi/cfdisSlice';
import { lcosSlice } from '../app/modules/Admin/_redux/lcos/lcosSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  cfdis:cfdisSlice.reducer,
  customers: customersSlice.reducer,
  roles: rolesSlice.reducer,
  prices: pricesSlice.reducer,
  taxes: taxesSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users: usersSlice.reducer,
  periods: periodsSlice.reducer,
  lcos: lcosSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
